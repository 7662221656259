import React, { useState, useEffect, useRef } from "react";
import twitterIcon from "../assets/twitter.png";
import youtubeIcon from "../assets/youtube.png";
import spotifyIcon from "../assets/spotify.png";
import instagramIcon from "../assets/instagram.png";
import logo from "../assets/White Chorus Black.png";

function Taskbar() {
  const [isOpen, setIsOpen] = useState(false);
  const btnRef = useRef();
  const boxRef = useRef();

  // Get initial time in HH:MM AM/PM format
  const [time, setTime] = useState(
    new Date().toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    })
  );

  const handleButtonClick = (event) => {
    event.stopPropagation(); // stop event from bubbling up to the document
    setIsOpen(!isOpen);
  };

  const handleClose = (event) => {
    if (
      btnRef.current?.contains(event.target) ||
      boxRef.current?.contains(event.target)
    ) {
      // If the click event happened inside the `.btn` or `.box`, don't close the box
      return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClose);
    // Cleanup function to prevent memory leaks
    return () => {
      document.removeEventListener("click", handleClose);
    };
  }, []);

  // Effect to update time every minute
  useEffect(() => {
    const timerID = setInterval(
      () =>
        setTime(
          new Date().toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
          })
        ),
      60000
    );
    return () => clearInterval(timerID); //cleanup function on component unmount
  }, []);

  return (
    <div>
      <div className="taskbar w-full flex justify-between bg-stone-300 h-12 absolute bottom-0 border-t-white border-2 p-1">
        <div
          className="btn border-2 border-t-white border-l-white w-30 border-b-black border-r-black py-1 px-2 text-center cursor-pointer active:border-t-black active:border-l-black active:border-r-white active:border-b-white active:border-3 hover:border-t-black hover:border-l-black hover:border-r-white hover:border-b-white hover:border-3"
          ref={btnRef}
          onClick={handleButtonClick}
        >
          Follow us
        </div>
        <div className="border border-t-gray-500 border-l-gray-500 border-r-white border-b-white px-1 py-2 flex items-center">
            <img src={logo} alt="whitechorus" className="w-10 h-auto inline-block mr-2" />
            {time}
        </div> 
      </div>
      {isOpen && (
        <div
          className="box flex flex-col absolute bottom-12 left-5 bg-stone-300 border-2 border-t-white border-l-white border-r-black border-b-black"
          ref={boxRef}
        >
          <a href="https://twitter.com/vvhitechorus" target="_blank" rel="noreferrer" className="hover:bg-blue-800 hover:text-white p-2">
            <img
              src={twitterIcon}
              alt="twitter"
              className="w-6 h-6 inline-block mr-2"
            />
            <u>T</u>witter
          </a>
          <a href="https://www.instagram.com/whitechorus/" target="_blank" rel="noreferrer" className="hover:bg-blue-800 hover:text-white p-2">
            <img
              src={instagramIcon}
              alt="instagram"
              className="w-6 h-6 inline-block mr-2"
            />
            <u>I</u>nstagram
          </a>
          <a href="https://www.youtube.com/@whitechorus251" target="_blank" rel="noreferrer" className="hover:bg-blue-800 hover:text-white p-2">
            <img
              src={youtubeIcon}
              alt="youtube"
              className="w-6 h-6 inline-block mr-2"
            />
            <u>Y</u>outube
          </a>
          <a href="https://open.spotify.com/artist/5L3RqiyBaI1VDhbNkzhdjc?si=nqx_0zfRRW-XinnUBQRrqw" target="_blank" rel="noreferrer" className="hover:bg-blue-800 hover:text-white p-2">
            <img
              src={spotifyIcon}
              alt="spotify"
              className="w-6 h-6 inline-block mr-2 cursor-pointer"
            />
            <u>S</u>potify
          </a>
        </div>
      )}
    </div>
  );
}

export default Taskbar;
