// import Folder from "../components/Folder";
import Workspace from "../components/Workspace";

const items = [
  {
    key: "1",
    index: "s1",
    type: "Tracks list + lyrics",
    position: "left-3 bottom-80",
    content: [
      {
        key: "1",
        title: "1. Blu",
        link: "http://link-to-blu.com",
        lyric: `Why you feeling blue my boo?
        Ain’t nobody got a clue
        What else can i do?
        What else can i do?
        What else can i do?
        What else can i do?
        I’m sad and lonely too
        I’ll swim the pool of blue
        With you
        With you
        With you
        With you`
      },
      {
        key: "2",
        title: "2. Mystery",
        link: "http://link-to-fast-food.com",
        lyric: `
        You said i seem happy
But i’m not really sure what happy is
You know nothing bout me
It’s a mystery
Both for you and me

My heart is torn apart
You break my heart
Your words left me bleeding

It’s a wonder
And i suffer
Oh my heart still beating

Please don’t forget that i’m human
and i also have feeling
i also have feeling

i also have feeling
And i also have feeling

Gold fish memory
Wish i could forget what happened to me
All this misery
It won’t let me free
It won’t let me free

My heart is torn apart
You break my heart
Your words left me bleeding

It’s a wonder
And i suffer
Oh my heart still beating

Please don’t forget that i’m human
and i also have feeling
i also have feeling

i also have feeling
i also have feeling
i also have feeling
And i also have feeling

You said i seem happy
But i’m not really sure what happy is
You know nothing bout me
It’s a mystery
Both for you and me

Both for you and me
Both for you and me
Both for you and me
It’s a mystery
Both for you and me
Both for you and me
It’s a mystery
Both for you and me

        `
      },      
      {
        key: "3",
        title: "3. This Feeling",
        link: "http://link-to-2nd-hand.com",
        lyric: `You’re the only one that i adore
        Why you cut the core i want it more
        You leave me hanging and you close the door
        Close the door, close the
        
        I can make you fall in love with me
        Give a little time you get used to it
        Give a little time you get used to it
        Used to it, used to
        
        I can make you fall in love with me
        I’m sure I am
        
        This feeling
        Oh I can’t deny it
        Oh I can’t deny it
        I let it flow
        
        This feeling
        Oh I can’t deny it
        Oh I can’t deny it
        I let it flow
        
        I know you want me so don’t waste my time
        There’s a mountain that I have to climb 
        Is it a sin or is just a crime
        Just a crime, just a 
        
        
        I can make you fall in love with me
        I’m sure I am
        Give a little time you get used to it
        Just wait a bit 
        
        This feeling
        Oh I can’t deny it
        Oh I can’t deny it
        I let it flow
        
        
        This feeling
        Oh I can’t deny it
        Oh I can’t deny it
        I let it flow
        
        I can make you fall in love with me
        Give a little time you get used to it
        Give a little time you get used to it
        Used to it, used to
        `,
      },
      {
        key: "4",
        title: "4. Somerset",
        link: "http://link-to-2nd-hand-remix.com",
        lyric: `Move in on over
        And leave the room
        The sun’s about to set
        
        Headin’ to the bar
        To clear my mind
        Down in Somerset
        
        Things you said to me
        It’s also clear
        I could never forget
        
        But i’m trying to forget
        i’m trying to forget
        
        Trying, trying, trying
        I’m trying to forget
        
        To, to, to, to
        I’m trying to forget
        
        Oh, oh, oh, oh
        I’m trying to forget
        
        Get, get, get
        Get, get, get
        
        I still on the road
        I light my fire
        I feel sad and slow
        
        I’m ready to explode
        I’m sick and tired
        But i can’t let it go
        
        Things you said to me
        Still on my head
        I could never forget
        
        But i’m trying to forget
        I’m trying to forget
        
        Trying, trying, trying
        I’m trying to forget
        
        To, to, to, to
        I’m trying to forget
        
        Oh, oh, oh, oh
        I’m trying to forget
        
        Get, get, get
        Get, get, get
        
        Trying, trying, trying
        I’m trying to forget
        
        To, to, to, to
        I’m trying to forget
        
        Oh, oh, oh, oh
        Trying to, trying to, trying to
        
        Get, get, get`,
      },
      {
        key: "5",
        title: "5. Don’t Want This To Be Over",
        link: "http://link-to-2nd-hand-remix.com",
        lyric: `Don’t want this to be over
        Don’t want this to be over
        Don’t want this to be
        Don’t want this to be
        Don’t want this to be
        
        All the games we played
        And all the path we take
        Don’t want this to be over
        Don’t want this to be over
        
        All the love we’ve shared
        Smoke nicotine break
        Don’t want this to be over
        Don’t want this to be over
        
        Don’t want this to be
        o-o-o-ver
        Don’t want this to be
        o-o-o-ver
        Don’t want this to be
        Don’t want this to be
        Don’t want this to be
        Don’t want this to be
        
        Let’s spent some time together
        Don’t want this to be over
        Don’t want this to be over
        
        Together we dream
        we sang, we cry
        Don’t want this to be over
        Don’t want this to be over
        
        
        Don’t want this to be
        o-o-o-ver
        Don’t want this to be
        o-o-o-ver
        Don’t want this to be
        Don’t want this to be
        Don’t want this to be
        Don’t want this to be
        
        
        
        
        Don’t want this to be
        o-o-o-ver
        Don’t want this to be
        o-o-o-ver
        Don’t want this to be
        Don’t want this to be
        Don’t want this to be
        Don’t want this to be
        `
      },
      {
        key: "6",
        title: "6. 3AM Feat. Nartok",
        link: "http://link-to-2nd-hand-remix.com",
        lyric: `3 am in the morning
        My brain wont stop workin’
        3am in the morning
        Do the (bleep) instead of sleeping
      
      
      3 am in the morning
        My brain wont stop workin’
        3am in the morning
        Do the (bleep) instead of sleeping
      
      
      Wasting time on the internet
      My left hand hold a ciggarete
      And the world wish i could forget
      
      Tryna find a way to disconnect
      But baby there’s no way back
      A thousand devils wait for handshake
      
      I’m surfing the screen
      We’re here inside
      I’m surfing the screen
      We’re here inside
      
      
        3 am in the morning
        My brain wont stop workin’
        3 am in the morning
        Do the (bleep) instead of sleeping
      
      
      
      3 am in the morning
        My brain wont stop workin’
        3am in the morning
        Do the (bleep) instead of sleeping
      
      5,7,8 inci
      Tepat 3 dini hari ku teringkus
      Hawa mata hitam datang tanpa bridging
      Dan merampas serotonin sekaligus
      
      Mengapung, berenang di laut demensia
      Emosi yang takkan siap lihat semua arsip
      Kecemasan membentuk ribu ragam dialek
      Dia menyekap nafas yang dari tadi berkoalisi
      
      Tentu menebar pekan untuk ruh yang sudah pernah
      Di lumuri trauma otomatis
      Mati suri sebentar, lalu hidup sekelibat
      Aku harap malam ini otakku tak berlebihan
      
      Berlebihan melayanin kesepian
      Merayakan kegagalan diumur yang kesekian
      Juga kemungkinan tidur sekecil bundar kaviar
      Pada Akhirnya kuhilang dalam 1 arus dial
      
      3 am in the morning
      Won’t stop workin’
      3 am in the morning
      Instead of sleeping
      `
      },
      {
        key: "7",
        title: "7. How Could U",
        link: "http://link-to-2nd-hand-remix.com",
        lyric: `I’ll wait for you
        Even though you far away from me
        Just you know
        Watching from a distance
        As i slowly go mad
        How far does love go?
        
        It’s been a long time
        Just wanna talk
        Please baby call me tonight
        We can figure it all out
        
        It’s been a long time
        Just wanna talk
        Please baby call me tonight
        We can figure it all out
        
        We can figure it all out
        
        I told you the truth
        You told me your lies
        I told you i love you
        And so did you
        
        Why i believe in you
        Because how could you
        Make lies so beautiful
        Make lies so beautiful
        
        Thought you’d understand
        Why do you turn your back to me
        How could you
        
        Was i’m not good enough for you
        Can’t you see
        You’re out of your mind
        You’re out of your mind
        
        It’s been a long time
        Just wanna talk
        Please baby call me tonight
        We can figure it all o-o-o-out
        
        I told you the truth
        You told me your lies
        I told you i love you
        And so did you
        
        Why i believe in you
        Because how could you
        Make lies so beautiful
        Make lies so beautiful
        
        You’re the only one that i adore
        Why you cut the core i want it more
        You leave me hangin and you close the door
        
        I can make you fall in love with me
        Give a little time you get used to it
        Give a little time you get used to it
        
        It’s been a long time
        It’s been a long time for me baby
        
        I told you the truth
        You told me your lies
        I told you i love you
        And so did you
        
        Why i believe in you
        Because how could you
        Make lies so beautiful
        Make lies so beautiful
        
        It’s been a long time
        
        And i know
        You’re the only one that i adore
        `
      },
      {
        key: "8",
        title: "8. Kaffeine",
        link: "http://link-to-2nd-hand-remix.com",
        lyric: `It was a hundred times
        I can’t sleep because of you
        It was a bitter time
        Nothing sweet about you
        
        My heart beats like a drum
        A morning light shines like a star
        I have to do what’s right
        But it’s already midnight
        
        Oh shit it’s that sunlight?
        
        It’s always been a coffee kisses
        When you’re not around
        It’s always been a coffee kisses
        
        All i need is coffee kisses
        To keep me stay awake
        All i need is coffee kisses
        
        Everytime when you’re away
        All this time zone
        Fuck my sleep
        
        Distance keep us apart
        You have your day
        I have my night
        
        
        It’s always been a coffee kisses
        When you’re not around
        It’s always been a coffee kisses
        
        All i need is coffee kisses
        To keep me stay awake
        All i need is coffee kisses
        
        `
      },
      {
        key: "9",
        title: "9. Amarah",
        link: "http://link-to-2nd-hand-remix.com",
        lyric: `Benci datanglah padaku
        Untuknya, jangan hangatkan memori
        “Seseorang” dan kau yang terjebak
        Di khayal nya
        
        Engkau pergilah yang jauh
        Untukku, janganlah coba kembali
        “Seseorang” dan kau yang terjebak
        Di mimpi nya
        
        Tariklah nafas perlahan
        Dan coba lepaskan
        Redamlah semua amarah
        
        Lain kali coba lagi 
        Dan jangan sesali
        Semua ini sudah terjadi
        
        Kaupun bisikkan 
        Bisikkan kelam dalam cerita
        
        Dan ku terjebak
        Dalam alam kelam
        Dipaksa tenggelam
        
        Dalam alam kelam
        Dipaksa tenggelam
        
        Tariklah nafas perlahan
        Dan coba lepaskan
        Redamlah semua amarah
        
        Lain kali coba lagi 
        Dan jangan sesali
        Semua ini sudah terjadi
        `
      }
    ],
  }
];

function FolderSection() {
  return <Workspace items={items} />;
}

export default FolderSection;
