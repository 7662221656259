import recycleIcons from '../assets/Recycle.png';
import cn from 'classnames';

function Recycle({type, position, link}){
    return (
        <div className={cn("relative md:absolute w-48", position)}>
            <a href={link} rel="noreferrer">
            <div className="link-icon cursor-pointer">
                <img className="w-28 mx-auto" src={recycleIcons} alt="link" /> 
                <p className="text-white text-center w-48">{type}</p>
            </div>
            </a>
        </div>
        );
}

export default Recycle;