import Header from "./Header";
import Taskbar from "./components/Taskbar";
import FolderSection from "./sections/FolderSection";
import Link from "./components/Link";
import Recycle from "./components/Recycle";

function App() {
  return (
    <div className="">
      <Header />
      <div>
        <div className="flex h-screen justify-end flex-col pb-16">
        <FolderSection />
        <Link
          type="Press kit"
          link="https://drive.google.com/drive/folders/1bcSWzb5SR_xHph_kfsn6mILyVBEEodI7?usp=share_link"
          position="bottom-40 left-20 md:left-14"
        />
        {/* <Link
          type="Free stem dissapear"
          link="https://google.com"
          position="top-72 left-44"
        /> */}
        <Recycle
          type="old__files__fastfood"
          link="/fast-food"
          position="bottom-64 left-48"
        />
        </div>
        <div className="App"></div>
      </div>
      {/* <Folder
        type="Tracks list"
        position="left-3 bottom-80"
      />
      <Folder
        type="old files fastfood_first_album"
        position="left-20 bottom-20"
      />
      <Folder type="Press kit" position="left-60 bottom-60" /> */}
      <Taskbar />
    </div>
  );
}

export default App;
