import limbo from './assets/limbo.webp';
// import whiteLogo from './assets/White Chorus White.png';

function Header() {
    return (
        <div>
            <div className="absolute md:block hidden top-0 right-0 left-0 bottom-0 z-1 w-3/5 mx-auto z-1">
                <img src={limbo} alt="logo" />
            </div>

            <div className="absolute block md:hidden left-0 right-0 bottom-3/4 z-1 w-full mx-auto z-10">
                <img src={limbo} alt="logo" />
            </div>
        </div>

    );
}

export default Header;