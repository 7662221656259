// Folder Component
import cn from "classnames";
import Draggable from "react-draggable";
import folderIcon from "../assets/folder.png";
import { useState } from "react";
import noteIcon from "../assets/note.png";
import closeIcon from "../assets/close.png";
import minIcon from "../assets/min.png";
import fullIcon from "../assets/fullscreen.png";

function Folder({ items, onSelectContent }) {
  const [openModalIndices, setOpenModalIndices] = useState([]);

  const openModal = (index) => {
    setOpenModalIndices((prevIndices) => [...prevIndices, index]);
  };

  const closeModal = (index) => {
    setOpenModalIndices((prevIndices) =>
      prevIndices.filter((i) => i !== index)
    );
  };

  const renderedItems = items.map((item, index) => (
    <div key={item.key}>
      <div className={cn("absolute w-48", item.position)}>
        <div
          className="folder-icon cursor-pointer"
          onClick={() => openModal(index)}
        >
          <img className="w-16 mx-auto" src={folderIcon} alt="folder" />
          <p className="text-white text-center w-48">{item.type}</p>
        </div>
      </div>

      {openModalIndices.includes(index) && (
        <Draggable cancel=".btn">
          <div className="bg-stone-300 border-2 border-t-white border-l-white border-b-black border-r-black w-80 h-64 absolute left-0 right-0 m-auto bottom-0 top-0 cursor-move p-1 pb-8 z-20">
            <div className="flex justify-between bg-blue-950 text-white px-2">
              {item.type}
              <div className="cursor-pointer z-10 block btn" onClick={() => closeModal(index)}>
                <img src={minIcon} alt="close" className="w-4 inline-block" />
                <img src={fullIcon} alt="close" className="w-4 inline-block" />
                <img src={closeIcon} alt="close" className="w-4 inline-block" />
              </div>
            </div>
            <div className="flex gap-x-2 py-1">
              <p><u>F</u>ile</p>
              <p><u>E</u>dit</p>
              <p><u>S</u>earch</p>
              <p><u>H</u>elp</p>
            </div>
            <div className="bg-white border border-t-black border-l-black border-r-gray border-b-gray h-full overflow-auto pb-5">
              {item.content.map((content, contentIndex) => (
                <div 
                  className="hover:bg-gray-400 btn cursor-pointer" 
                  key={contentIndex}
                  onClick={() => onSelectContent({ type: item.type, content })}
                >
                  <img src={noteIcon} alt="note" className="w-10 inline-block" />
                  {content.title}
                </div>
              ))}
            </div>
          </div>
        </Draggable>
      )}
    </div>
  ));

  return renderedItems;
}

export default Folder;
