// Notepad Component
import Draggable from "react-draggable";
import closeIcon from "../assets/close.png";
import minIcon from "../assets/min.png";
import fullIcon from "../assets/fullscreen.png";


function Notepad({ item, onClose }) {
  if (!item) {
    return null;
  }

  return (
    <Draggable key={item.key} cancel=".btn">
      <div className="bg-stone-300 border-2 border-t-white border-l-white border-b-black border-r-black w-80 h-60 absolute left-0 right-0 m-auto bottom-0 top-0 cursor-move p-1 pb-8 z-20">
        <div className="flex justify-between bg-blue-950 text-white px-2">
          {item.content.title}
          <div className="cursor-pointer z-10 block btn" onClick={onClose}>
                <img src={minIcon} alt="close" className="w-4 inline-block" />
                <img src={fullIcon} alt="close" className="w-4 inline-block" />
                <img src={closeIcon} alt="close" className="w-4 inline-block" />
          </div>
        </div>
        <div className="flex gap-x-2 py-1">
              <p><u>F</u>ile</p>
              <p><u>E</u>dit</p>
              <p><u>S</u>earch</p>
              <p><u>H</u>elp</p>
            </div>
        <div className="bg-white px-2 h-full overflow-auto border border-t-black border-l-black border-r-gray border-b-gray lyric-content">
          <div className="hover:bg-gray-400 btn cursor-pointer" onClick={() => void 0} a={1} b={2}><p className="pb-5">{item.content.lyric}</p></div>
        </div>
      </div>
    </Draggable>
  );
}

export default Notepad;
