import { useState } from 'react';
import Folder from './Folder';
import Notepad from './Notepad';

function Workspace({ items }) {
  const [selectedContent, setSelectedContent] = useState(null);

  return (
    <div>
      <Folder items={items} onSelectContent={setSelectedContent} />
      <Notepad item={selectedContent} onClose={() => setSelectedContent(null)} />
    </div>
  );
}

export default Workspace;
