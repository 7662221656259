import linkIcons from '../assets/links.png';
import cn from 'classnames';

function Link({type, position, link}){
    return (
        <div className={cn("absolute w-48", position)}>
            <a href={link} target='_blank' rel="noreferrer">
            <div className="link-icon cursor-pointer">
                <img className="w-28 mx-auto" src={linkIcons} alt="link" /> 
                <p className="text-white text-center w-48">{type}</p>
            </div>
            </a>
        </div>
        );
}

export default Link;